import Marquee from 'react-fast-marquee';

export interface Partner {
  href?: string;
  target?: string;
  imageAlt?: string;
  imageUrl?: string;
}

interface Props {
  partners?: Partner[];
}

function PartnersMarquee(props: Props) {
  return (
    <Marquee autoFill pauseOnHover>
      {props.partners?.map((partner, index) => (
        <a key={index} href={partner.href} target={partner.target}>
          <img
            src={partner.imageUrl}
            alt={partner.imageAlt}
            loading="lazy"
            decoding="async"
            className="h-[10rem] object-cover"
          />
        </a>
      ))}
    </Marquee>
  );
}

export default PartnersMarquee;
